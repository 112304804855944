<template>
  <div>
    <p class="border-0 mb-2">
      <label for="username"> {{ $t("Username") }}</label>
      <CInput id="username" v-model="modalInfo.username" />
    </p>
    <p class="border-0 mb-2">
      <label for="password"> {{ $t("Password") }}</label>
      <CInput id="password" v-model="modalInfo.password" />
    </p>
    <div>
      <label for="pool"> {{ $t("Pool") }}</label>
      <div class="bg-white text-main_black rounded-md">
        <CSelect
          class="mb-0 bg-transparent p-0"
          :options="
            allPools.map((res) => {
              return { ...res, value: res.name };
            })
          "
          name="pool_id"
          :value="modalInfo.pull ? modalInfo.pull.name : ''"
          @update:value="updatePoolValues"
        />
      </div>
    </div>
    <div class="mt-3">
      <label for="customer"> {{ $t("Customer") }}</label>
      <div class="bg-white text-main_black rounded-md">
        <CSelect
          id="customer"
          class="mb-0 bg-transparent p-0"
          :options="
            allCustomers.map((res) => {
              return { ...res, value: res.email };
            })
          "
          name="user_id"
          :value="modalInfo.user ? modalInfo.user.email : ''"
          @update:value="updateCustomerValues"
        />
      </div>
    </div>
    <div class="text-right my-3">
      <button
        type="button"
        class="btn btn-lightGray bg-main_color text-white rounded-md text-uppercase"
        @click="$store.state.miners.isEdit.status === 'add' ? addWorker() : editWorker()"
      >
        {{ $store.state.miners.isEdit.status }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "AddWorkerModal",
  props: ["content"],
  data() {
    return {
      modalInfo: {
        pool_id: null,
        poolName: "",
        user_id: null,
        customer: "",
        username: "",
        password: "",
        user: {},
        pull: {}
      },
      errors: [],
    };
  },
  created() {
    this.$store.dispatch("miners/GET_ALL_POOLS");
    this.$store.dispatch("miners/GET_ALL_CUSTOMERS");
  },
  computed: {
    ...mapState("miners", ["allPools", "allCustomers"]),
  },
  watch: {
    content: {
      handler: function () {
        const getVal = (key) => {
          return this.content && this.content[key] ? this.content[key] : null;
        };
        this.modalInfo.user = getVal("user");
        this.modalInfo.pull = getVal("pull");
        this.modalInfo.user_id = getVal("user_id");
        this.modalInfo.password = getVal("password");
        this.modalInfo.username = getVal("username");
        this.modalInfo.pool_id = getVal("pool_id");
        const isExist = this.allPools.filter((res) => res.id == this.modalInfo.pool_id);
        this.modalInfo.poolName = isExist.length > 0 ? isExist[0].value : "";
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("miners", ["EDIT_WORKER", "ADD_WORKER"]),
    addWorker() {
      this.modalInfo.pool_id =
        this.allPools.length > 0 && !this.modalInfo.pool_id
          ? this.allPools[0].id
          : this.allPools.length === 0
          ? this.errors.push("error occured")
          : this.modalInfo.pool_id;
      this.modalInfo.user_id =
        this.allCustomers.length > 0 && !this.modalInfo.user_id
          ? this.allCustomers[0].id
          : this.allCustomers.length === 0
          ? this.errors.push("error occured")
          : this.modalInfo.user_id;
      this.ADD_WORKER({
        body: {
          ...this.modalInfo,
        },
        page: this.$route.params.id,
        pageUrls: this.$route.query,
      }).then(() => {
        this.$emit("triggerClose");
      });
    },
    editWorker() {
      
      this.EDIT_WORKER({
        body: {
          ...this.modalInfo,
        },
        id: this.content.id,
        page: this.$route.params.id,
        pageUrls: this.$route.query,
      }).then(() => {
        this.$emit("triggerClose");
        // window.location.reload();
      });
    },
    updatePoolValues(value, e) {
      this.modalInfo = {
        ...this.modalInfo,
        [e.target.name]: this.allPools[e.target.selectedOptions[0].dataset.key].id,
      };
    },
    updateCustomerValues(value, e) {
      this.modalInfo = {
        ...this.modalInfo,
        [e.target.name]: this.allCustomers[e.target.selectedOptions[0].dataset.key].id,
      };
    },
  },
};
</script>

<style scoped></style>
