<template>
  <div>
    <p class="border-0 mb-2">
      <label for="first_name">{{ $t("Container name") }}</label>
      <CInput id="container-name" v-model="modalInfo.name" />
      <span class="error-message mb-4" v-if="isExist">{{ $t("This name already exists") }}</span>
    </p>
    <div>
      <label for="type">{{ $t("Farm") }}</label>
      <div class="bg-white text-main_black rounded-md">
        <CSelect
          class="mb-0 bg-transparent p-0"
          :options="allFarms"
          name="farm_id"
          :value="modalInfo.farm_name"
          @update:value="updateValues"
        />
      </div>
    </div>
    <div class="text-right my-3">
      <button
        type="button"
        class="btn btn-lightGray bg-main_color text-white rounded-md text-uppercase"
        @click="
          $store.state.miners.isEdit.status === 'add' ? addContainer() : editContainer()
        "
      >
        {{ $store.state.miners.isEdit.status }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "AddContainerModal",
  props: ["content"],
  data() {
    return {
      modalInfo: {
        farm_id: null,
        farm_name: "",
        name: "",
        status: "active",
        description: "s",
      },
      isExist: false,
      errors: [],
    };
  },
  computed: {
    ...mapState("miners", ["allFarms"]),
  },
  watch: {
    content: {
      handler: function () {
        const getVal = (key) => {
          return this.content && this.content[key] ? this.content[key] : null;
        };
        this.modalInfo.name = getVal("container_name");
        this.modalInfo.farm_id = getVal("farm_id");
        const isExist = this.allFarms.filter((res) => res.id == this.modalInfo.farm_id);
        this.modalInfo.farm_name = isExist.length > 0 ? isExist[0].value : "";
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("miners", ["EDIT_CONTAINER", "ADD_CONTAINER"]),
    addContainer() {
      this.modalInfo.farm_id =
        this.allFarms.length > 0 && !this.modalInfo.farm_id
          ? this.allFarms[0].id
          : this.allFarms.length === 0
          ? this.errors.push("error occured")
          : this.modalInfo.farm_id;
      this.ADD_CONTAINER({
        body: {
          ...this.modalInfo,
        },
        page: this.$route.params.id,
        pageUrls: this.$route.query,
      }).then(() => {
        this.$emit("triggerClose");
      }).catch(res => {
        if(res.response.status == 500){
          this.isExist = true;
        }
      });
    },
    editContainer() {
      this.EDIT_CONTAINER({
        body: {
          ...this.modalInfo,
        },
        id: this.content.id,
        page: this.$route.params.id,
        pageUrls: this.$route.query,
      }).then(() => {
        this.$emit("triggerClose");
        // window.location.reload();
      });
    },
    updateValues(value, e) {
      this.modalInfo = {
        ...this.modalInfo,
        [e.target.name]: this.allFarms[e.target.selectedOptions[0].dataset.key].id,
      };
    },
  },
};
</script>

<style scoped></style>
