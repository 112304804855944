<template>
  <CModal
      :title="$store.state.miners.isEdit.status === 'add' ? `Add new ${pageUrl}` : `Edit ${pageUrl}`"
      color="lightGray"
      class="text-main_black bg-milk_mix"
      :show.sync="isOpened"
      :close-on-backdrop="false"
  >
    <CForm>
      <component
        :is="current_component"
        @triggerClose="triggerCloseModal"
        :content="$store.state.miners.isEdit.content"
          />
    </CForm>
    <template #footer="{ item }" class="d-none p-0">
      <span class="d-none"/>
    </template>
  </CModal>
</template>

<script>
export default {
  name: "index.vue",
  props: ['pageUrl','current_component','triggerModal'],
  data(){
    return {
      isOpened: false
    }
  },
  watch:{
    'triggerModal':{
      handler:function (e){
        this.isOpened = true;
      },
      deep: true
    },
    '$store.state.miners.isEdit': {
      handler: function (e){
        const obj = {...e};
        if(obj.content.page === this.pageUrl) this.isOpened = true;
      },
      deep: true
    }
  },
  methods:{
    triggerCloseModal(){
      this.isOpened = false;
    }
  }
}
</script>

<style scoped>

</style>