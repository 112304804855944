<template>
  <div>
    <p class="border-0 mb-2">
      <label for="address"> {{ $t("Pool Address") }}</label>
      <CInput id="address" v-model="modalInfo.address" />
    </p>
    <p class="border-0">
      <label for="name">{{ $t("Name") }}</label>
      <CInput id="name" name="name" v-model="modalInfo.name" />
    </p>
    <p class="border-0">
      <label for="description"> {{ $t("Description") }}</label>
      <CInput id="description" v-model="modalInfo.description" />
    </p>
    <div class="text-right">
      <button
        type="button"
        class="btn btn-lightGray bg-main_color text-white rounded-md text-uppercase"
        @click="$store.state.miners.isEdit.status === 'add' ? savePool() : editPool()"
      >
        {{ $store.state.miners.isEdit.status }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "AddPoolModal.vue",
  props: ["content"],
  data() {
    return {
      modalInfo: {
        address: "",
        description: "",
        name: "",
      },
      errors: [],
    };
  },
  watch: {
    content: {
      handler: function () {
        const getVal = (key) => {
          return this.content && this.content.item ? this.content.item[key] : null;
        };
        this.modalInfo.address = getVal("address");
        this.modalInfo.name = getVal("name");
        this.modalInfo.description = getVal("description");
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("miners", ["ADD_POOL", "EDIT_POOL"]),
    savePool() {
      this.ADD_POOL({
        body: {
          ...this.modalInfo,
        },
        page: this.$route.params.id,
        pageUrls: this.$route.query,
      }).then(() => {
        this.$emit("triggerClose");
      });
    },
    editPool() {
      this.EDIT_POOL({
        body: {
          ...this.modalInfo,
        },
        id: this.content.item.id,
        page: this.$route.params.id,
        pageUrls: this.$route.query,
      }).then(() => {
        this.$emit("triggerClose");
      });
    },
  },
};
</script>

<style scoped></style>
