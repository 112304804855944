<template>
  <div class="add-ticket">
    <p class="border-0 mb-2">
      <label for="topic">{{ $t("Topic") }}</label>
      <CInput id="topic" v-model="modalInfo.subject" />
    </p>
    <div>
      <label for="type">{{ $t("Farm") }}</label>
      <div class="bg-white text-main_black rounded-md">
        <CSelect
          class="mb-0 rounded-lg bg-transparent p-0"
          :options="allFarms"
          name="farm_id"
          :value="modalInfo.farm_name"
          @update:value="updateValues"
        />
      </div>
    </div>
    <div class="bg-white text-main_black rounded-md">
      <label for="farm" class="mt-4">{{ $t("Message") }}</label>
      <div class="bg-white text-main_black rounded-md">
        <textarea class="form-control mb-1" v-model="modalInfo.body"> </textarea>
      </div>
    </div>
    <div class="input-group mb-3 mt-4">
      <!-- <div class="input-group-prepend">
        <span class="input-group-text" id="inputGroupFileAddon01">Upload</span>
      </div> -->

      <div
        v-if="files.length > 0"
        class="d-flex flex-wrap w-100 mb-1 text-main_black justify-content-between"
      >
        <div
          v-for="(file, i) in files"
          :key="i"
          class="d-flex mt-1 w-100 justify-content-between"
        >
          <div>
            <img class="mr-2" src="@/assets/icons/attach-done.svg" alt="" />
            {{ file.fileName }}
          </div>
          <img
            @click="deleteFile(file)"
            class="cursor-pointer"
            src="@/assets/icons/close.svg"
            alt=""
          />
        </div>
      </div>
      <div class="custom-file">
        <input
          type="file"
          class="custom-file-input cursor-pointer"
          id="inputGroupFile01"
          aria-describedby="inputGroupFileAddon01"
          @change="updateFile"
        />
        <label class="custom-file-label cursor-pointer" for="inputGroupFile01">
          <img class="" src="@/assets/icons/attach.svg" alt="" />
          {{ $t("Attach file") }}
        </label>
      </div>
    </div>
    <div class="text-right">
      <button
        type="button"
        class="btn btn-lightGray bg-main_color text-white rounded-md text-uppercase"
        @click="$store.state.miners.isEdit.status === 'add' ? saveTicket() : editTicket()"
      >
        {{ $store.state.miners.isEdit.status }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "AddTicketModal.vue",
  data() {
    return {
      modalInfo: {
        farm_id: null,
        farm_name: "",
        subject: "",
        body: "",
        status: "open",
        attachment: 0,
      },
      errors: [],
      files: [],
    };
  },
  created() {
    if(this.allFarms.length === 0)
      this.$store.dispatch("miners/GET_ALL_FARMS");
  },
  watch: {},
  methods: {
    ...mapActions("miners", ["ADD_TICKET", "EDIT_TICKET"]),
    saveTicket() {
      // TODO Save all attachments

      const formData = new FormData();
      Object.keys(this.modalInfo).forEach((res) => {
        if(!this.modalInfo[res] && res === 'farm_id')
          formData.append(`${res}`,this.allFarms[0].id)
        else
          formData.append(`${res}`, this.modalInfo[res]);
      });
      
      this.ADD_TICKET(formData).then(() => {
        this.$emit("triggerClose");
      });
    },
    editTicket() {
      this.EDIT_TICKET({
        body: {
          ...this.modalInfo,
        },
        id: this.content.item.id,
        page: this.$route.params.id,
        pageUrls: this.$route.query,
      }).then(() => {
        this.$emit("triggerClose");
      });
    },
    updateValues(value, e) {
      this.modalInfo = {
        ...this.modalInfo,
        [e.target.name]: this.allFarms[e.target.selectedOptions[0].dataset.key].id,
      };
    },
    deleteFile(file) {
      const index = this.files.indexOf(file);
      if (index > -1) {
        this.files.splice(index, 1);
      }
      this.modalInfo.attachment = this.files.length;
    },
    updateFile(e) {
      const files = e.target.files;
      const new_files = [];
      let lastFileUrl = "";
      for (let i = 0; i < files.length; i++) {
        const fileReader = new FileReader();
        fileReader.addEventListener("load", () => {
          lastFileUrl = fileReader.result;
        });
        fileReader.readAsDataURL(files[i]);
        new_files.push(files[i]);
        this.files.push({
          fileName: files[i].name,
          file: files[i],
          url: lastFileUrl,
        });
      }
      this.modalInfo.attachment = new_files[0];
    },
  },
  computed: {
    ...mapState("miners", ["allFarms"]),
  },
};
</script>

<style scoped></style>
