<template>
  <div>
    <p class="border-0 mb-2">
      <label for="first_name"> {{ $t("Farm name") }}</label>
      <CInput id="location" v-model="name_location" />
      <span class="error-message" v-if="isExist">{{ $t("This name already exists") }}</span>
    </p>
    <div class="text-right">
      <button
        type="button"
        class="btn btn-lightGray bg-main_color text-white rounded-md text-uppercase"
        @click="$store.state.miners.isEdit.status === 'add' ? saveFarm() : editFarm()"
      >
        {{ $store.state.miners.isEdit.status }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "AddFarmModal.vue",
  props: ["content"],
  data() {
    return {
      name_location: "",
      isExist: false,
    };
  },
  watch: {
    content: {
      handler: function () {
        this.name_location = this.content && this.content.name ? this.content.name : null;
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("miners", ["ADD_FARM", "EDIT_FARM"]),
    saveFarm() {
      this.ADD_FARM({
        name: this.name_location,
        status: "active",
        description: "s",
      }).then(() => {
        this.$emit("triggerClose");
      }).catch(res => {
        if(res.response.status == 500){
          this.isExist = true
        }
      });
    },
    editFarm() {
      this.EDIT_FARM({
        body: {
          name: this.name_location,
          status: "active",
          description: "s",
        },
        id: this.content.id,
      }).then(() => {
        this.$emit("triggerClose");
      });
    },
  },
};
</script>

<style scoped></style>
