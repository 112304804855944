<template>
  <CSidebar
    fixed
    :minimize="minimize"
    :show="show"
    :unfoldable="true"
    class="bg-white"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand class="text-decoration-none bg-white pt-4" to="/Farms/1">
      <!--      <CIcon-->
      <!--        class="c-sidebar-brand-full"-->
      <!--        name="logo"-->
      <!--        size="custom-size"-->
      <!--        :height="35"-->
      <!--        viewBox="0 0 556 134"-->
      <!--      />-->
      <!--      <CIcon-->
      <!--        class="c-sidebar-brand-minimized"-->
      <!--        name="logo"-->
      <!--        size="custom-size"-->
      <!--        :height="35"-->
      <!--        viewBox="0 0 110 134"-->
      <!--      />-->
      <div class="logo">
        <svg
          width="75"
          height="24"
          viewBox="0 0 75 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <mask
            id="mask0"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="75"
            height="24"
          >
            <rect width="75" height="23.7624" fill="url(#pattern0)" />
          </mask>
          <g mask="url(#mask0)">
            <rect
              x="-0.742188"
              y="-0.742676"
              width="77.2277"
              height="25.2475"
              fill="#0F5B84"
            />
          </g>
          <defs>
            <pattern
              id="pattern0"
              patternContentUnits="objectBoundingBox"
              width="1"
              height="1"
            >
              <use xlink:href="#image0" transform="scale(0.00990099 0.03125)" />
            </pattern>
            <image
              id="image0"
              width="101"
              height="32"
              xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGUAAAAgCAYAAAAL6bYQAAAENklEQVRogd2a7XHbOBCGH2euAHYQlkBXELqCKBWc1IFdQewK7FQgpgLnKqA6sDoQO7A62PsBIgRBAAQojkTpncGYAhbgAov9pO9EBAul3bFgHIH9xLnljHycgqZtHUREt0cR+ZTrw0EU70S0TES2srx91iKSS8vnnShNeQUez35H5sUO+IHSHhcK4B3Iz8RPKo7AA7C/E5ESqC2CPf7NLQU5wwPeozZm816g9pg56C+5z4I+TzvgAVHqrPEpIoXEmYIltFxEPixT8CHKTGmaQobmamvRXLK9WrwViLJnGusFMJnaMnELJiSQS/Nst4Mpgy+WOjXn0tsZ8dcWG32/cJusCticjbN4NMZzbgvlWmEKZtP+vRaBDHArQgElmHuuXCBwW0IBv8l6Yhh5LRb/JNKXnn4ztMxQhxOCnYnnwIru4PbAn0TeQgLRIb8rXPahbP829G2+ub+m/T0m8LTQW/rRVzkSJYRQt5FQOUKnaX0hocaHxIfnobDXjMzscDlmr89W/7sxVkj//HwYO1dzjedUTdFo6G6PvjklsAVeUEmQRumYo7XErCQ0wG/gK7Cmy8DvCd+yUJRl32BNm6IxJlZtA7XPPf7am50YxkOmaYp9e8w1YufkxlhtjT0aY6G6Vkwe4stjxjTG5juTLp/4GJm7SnxXbb5vbkffJNCujOcXa+zNeP7umR+bh7jyGF/ZJYSfdGWdpwBdgbIY+t2hepwTU83XN+C5ff5O5/h+J6zx1XjeOcbvUYfm2lBqYqgFU9PxGmvK/kXtt2x/v3n4BSU0zZd+ZxNY2w2Zz9EfPGpqm4EYkzfWTGer3x0zL5chfObRh5WH3jaT64T9mGcx2XxV7S14QN3OY3tL3hPWaEbGdfDgCq911q6R05kMHzKG/FX0TaULFf0b/+qhe6fj9amdNw0yj6M3w9rYOaYzd4W+h3asjryZIv5iYwqtj++10Wdr1zZh3bM5+imhn5kc2revpHOq/3nmuxz4mqHGZPR9CUwru1R02vKTbs/P7XtB7enkcs5UR6+dHwwz3Fg0KNPxiBLCgX6eAurgq8AaLgeu5+o8ZQ6BaLyghJ6hLtIGJSCNnOEHQ1D7qqLfIvM6epcZss2A3UzVj1kv1slOMVkxfNfGWOHh3YZv707zlaopD57+0H+V6DmNZ3yD+v5h5i0NyhTExvemxvxq556qIT6+N3Sm9Yj/TEzYawSRKpRdIn3snFC5Iha6dD+Xydp5+hv6h9w4qU7ArZXu5/YhF8EtCSUkkC3jecxiMDX6WhrGBLI2+hevNbamlJdg4kSEBGJ/cFuzTI0xedzb2finXM+/GWUtr1NK97X4a1jnbLkM63j5nYjkqMTtFlDhNk8ubVoiKmDzBRXSLd7ORuCN8dL97mzcpGNP+51G+5SK5TPtwx8U76EPT9AJxq4wXxpHVPnm73ed/wHxp8SE54BKyAAAAABJRU5ErkJggg=="
            />
          </defs>
        </svg>
        <span>Dashboard</span>
      </div>
    </CSidebarBrand>
    <CRenderFunction
      flat
      :content-to-render="currentNav ? currentNav : []"
      class="sidebar__nav"
    >
    </CRenderFunction>

    <div class="language pb-4">
      <CTabs
        variant="pills"
        :active-tab="langStatus ? (langStatus === 'en' ? 1 : 0) : 1"
        @update:activeTab="
          (e) => $store.commit('sidebar/changeLanguage', e ? 'en' : 'ru')
        "
      >
        <CTab title="РУСС"> </CTab>
        <CTab title="ENG"> </CTab>
      </CTabs>
      <div
        @click="Logout"
        class="log-out pl-3 text-main_color mt-3 d-block cursor-pointer"
      >
        <img src="@/assets/icons/log-out.svg" alt="" class="pr-2" /> {{ $t("logout") }}
      </div>
    </div>
  </CSidebar>
</template>

<script>
import nav from "../_nav";
import { mapState } from "vuex";
import { Trans } from "../../plugins/Translation";
import { _store } from "../../api/helper";
import i18n from "../../i18n";

export default {
  name: "TheSidebar",
  // props:["userInfo"],
  nav,
  computed: {
    show() {
      return this.$store.state.sidebar.sidebarShow;
    },
    minimize() {
      return this.$store.state.sidebar.sidebarMinimize;
    },
    ...mapState("sidebar", ["langStatus"]),
    ...mapState("user", ["userInfo"]),
  },
  data() {
    return {
      currentNav: nav,
    };
  },
  created() {
    this.currentNav = this.computeCurrentNav();
  },
  methods: {
    computeCurrentNav() {
      return nav.map((res) => {
        res._children = res._children.map((obj) => {
          if (obj.name === i18n.t("Users")) {
            if (this.userInfo && this.userInfo.user.role !== "admin") return {};
            return {
              ...obj,
              show: this.$route.name === "profile" || this.$route.name === "users",
            };
          }
          return obj;
        });
        return res;
      });
    },
    Logout() {
      _store.remove("btsminer_token");
      this.$router.push("/pages/login");
    },
  },
  watch: {
    langStatus: {
      handler: function (e) {
        Trans.changeLocale(e).then(() => {
          window.location.reload();
        });
      },
      deep: true,
    },
    "$store.state.user.userInfo": {
      handler: function (e) {
        this.currentNav = this.computeCurrentNav();
      },
      deep: true,
    },
    "$route.name": {
      handler: function () {
        this.currentNav = this.computeCurrentNav();
      },
      deep: true,
    },
  },
};
</script>
