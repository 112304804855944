<template>
  <CHeader fixed with-subheader class="pt-0 pt-lg-4 border-bottom-0 modal-vue">
    <CContainer fluid>
      <CRow
        class="flex-nowrap justify-content-between pb-4 pt-4"
        v-if="pageUrl !== 'AddMiner'"
      >
        <CToggler
          in-header
          v-if="
            pageUrl !== 'tickets' &&
            pageUrl !== 'monitoring' &&
            pageUrl !== 'MinerInfo' &&
            pageUrl !== 'Statistics'
          "
          class="ml-lg-3 burger-menu d-none d-lg-block"
          @click="$store.commit('sidebar/toggleSidebarDesktop')"
        />
        <CToggler
          in-header
          v-if="
            pageUrl !== 'tickets' && pageUrl !== 'monitoring' && pageUrl !== 'MinerInfo'
          "
          class="ml-lg-3 d-lg-none burger-menu"
          @click="$store.commit('sidebar/toggleSidebarMobile')"
        />
        <CCol
          col="2"
          class="header-back"
          v-if="
            pageUrl === 'tickets' ||
            pageUrl === 'monitoring' ||
            pageUrl === 'MinerInfo' ||
            pageUrl === 'Statistics'
          "
        >
          <a
            @click="$router.go(-1)"
            class="d-flex align-items-center pl-3 cursor-pointer text-main_color text-decoration-none"
          >
            <svg
              class="mr-3"
              width="19"
              height="16"
              viewBox="0 0 19 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.857145 8.00001L7.52381 14.6667M18 8.00001H0.857145H18ZM0.857145 8.00001L7.52381 1.33334L0.857145 8.00001Z"
                stroke="#0F5B84"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            Go Back
          </a>
        </CCol>
        <CCol
          col="3"
          md="6"
          class="relative search-mobile"
          v-if="
            pageUrl !== '' &&
            pageUrl !== 'profile' &&
            pageUrl !== 'MinerInfo' &&
            pageUrl !== 'tickets' &&
            pageUrl !== 'monitoring' &&
            pageUrl !== 'Statistics'
          "
        >
          <form @submit.stop="search">
            <input class="header-search" type="text" v-model="searchText" />
            <img
              src="@/assets/icons/header/header-search.svg"
              alt=""
              class="header-search-icon"
            />
          </form>
        </CCol>
        <CCol>
          <div class="d-flex justify-content-between ml-2">
            <!-- <CButton
              v-if="pageUrl == 'ticket' && userInfo && userInfo.user.role == 'customer'"
              @click="triggerModal"
              class="header-button bg-white text-uppercase d-flex justify-content-center align-items-center"
            >
              <img src="@/assets/icons/header/button-pluse.svg" />
              <span>{{ $t("ADD") }} {{ $t(pageUrl) }}</span>
            </CButton> -->
            <CButton
              v-if="
                pageUrl !== 'miner' &&
                pageUrl !== 'user' &&
                pageUrl !== 'profile' &&
                pageUrl !== 'MinerInfo' &&
                pageUrl !== '' &&
                pageUrl !== 'tickets' &&
                pageUrl !== 'monitoring' &&
                pageUrl !== 'Statistics' &&
                userInfo
              "
              @click="triggerModal"
              class="header-button bg-white text-uppercase d-flex justify-content-center align-items-center"
            >
              <img src="@/assets/icons/header/button-pluse.svg" />
              <span>{{ $t("ADD") }} {{ $t(pageUrl) }}</span>
            </CButton>
            <CButton
              v-if="pageUrl === 'user'"
              @click="userModal = true && userInfo && userInfo.user.role == 'admin'"
              class="header-button bg-white text-uppercase d-flex justify-content-center align-items-center"
            >
              <img src="@/assets/icons/header/button-pluse.svg" />
              <span>{{ $t("ADD") }} {{ $t(pageUrl) }}</span>
            </CButton>
            <router-link
              to="/AddMiner"
              class="button-link header-button bg-white text-uppercase d-flex justify-content-center align-items-center"
              v-if="pageUrl === 'miner' && userInfo && userInfo.user.role == 'admin'"
            >
              <img src="@/assets/icons/header/button-pluse.svg" />
              <span>{{ $t("ADD") }} {{ $t("MINER") }}</span>
            </router-link>

            <!-- <TheHeaderDropdownAccnt/> -->
            <router-link
              to="/Settings"
              class="d-flex align-items-center header-account ml-auto"
            >
              <div class="d-flex align-items-center header-account ml-auto">
                <div class="c-name">
                  {{ userInfo ? userInfo.user.full_name : "" }}
                </div>
                <div class="c-avatar c-avatar-img overflow-hidden">
                  <img
                    :src="
                      userInfo && userInfo.image
                        ? localApi + userInfo.image
                        : require('@/assets/icons/users/avatar.png')
                    "
                    width="75"
                    height="75"
                  />
                </div>
              </div>
            </router-link>
          </div>
        </CCol>
      </CRow>
      <div v-else></div>
    </CContainer>
    <!-- <CSubheader class="px-3 bg-bg-gray">
      <CBreadcrumbRouter class="border-0 mb-0"/>
    </CSubheader> -->
    <ModalHOC
      :current_component="current_component"
      :page-url="pageUrl"
      :triggerModal="warningModal"
    />
    <CModal
      title="Create user"
      color="lightGray"
      :close-on-backdrop="false"
      class="text-main_black bg-milk_mix"
      v-if="pageUrl === 'user'"
      :show.sync="userModal"
    >
      <CSpinner grow size="md" v-if="loading" />
      <CForm v-else>
        <p class="errors text-red" v-if="modalInfo.errorList">
          <span
            v-for="(obj, i) in Object.keys(modalInfo.errorList)"
            :key="i"
            class="d-block"
          >
            {{ modalInfo.errorList[obj][0] }}
          </span>
        </p>
        <p class="border-0 mb-2">
          <label for="first_name">First Name</label>
          <CInput id="first_name" name="first_name" v-on:change="updateValues"> </CInput>
        </p>
        <p class="border-0">
          <label for="last_name">Last Name</label>
          <CInput id="last_name" name="last_name" v-on:change="updateValues"> </CInput>
        </p>
        <p class="border-0">
          <label for="email">Email</label>
          <CInput id="email" v-on:change="updateValues" name="email"> </CInput>
        </p>
        <p class="border-0">
          <label for="mobile">Mobile</label>
          <CInput id="mobile" name="phone" v-on:change="updateValues"> </CInput>
        </p>
        <div>
          <label for="type">Type</label>
          <div
            class="bg-white text-main_black border-gray_input rounded-md overflow-hidden px-3 py-2"
          >
            <CCard
              @click="modalInfo.collapseType = !modalInfo.collapseType"
              color="white border-0"
              class="m-0 d-flex flex-row justify-content-between align-items-center cursor-pointer"
            >
              <span>{{
                modalInfo.curUser && modalInfo.curUser.role
                  ? modalInfo.curUser.role
                  : "Admin"
              }}</span>
              <img src="@/assets/icons/users/arrow_bottom.svg" alt="" width="10px " />
            </CCard>
            <CCollapse :show="modalInfo.collapseType" class="border-0 p-0">
              <CCard class="border-0 p-0 mt-2 mb-0 radio_card">
                <CInputRadioGroup
                  :options="[
                    { value: 'admin', label: 'Admin' },
                    { value: 'operator', label: 'Operator' },
                    {
                      value: 'customer',
                      label: 'Customer',
                      props: { desription: 'description text' },
                    },
                  ]"
                  class="text-gray"
                  checked="admin"
                  name="role"
                  @update:checked="updateValues"
                >
                </CInputRadioGroup>
              </CCard>
            </CCollapse>
          </div>
        </div>
        <div class="mt-4">
          <label for="type">Status</label>
          <div
            class="bg-white text-main_black border-gray_input rounded-md overflow-hidden px-3 py-2"
          >
            <CCard
              @click="modalInfo.collapse = !modalInfo.collapse"
              color="white border-0"
              class="m-0 d-flex flex-row justify-content-between align-items-center cursor-pointer"
            >
              <span>{{
                modalInfo.curUser && modalInfo.curUser.status
                  ? modalInfo.curUser.status
                  : "Active"
              }}</span>
              <img src="@/assets/icons/users/arrow_bottom.svg" alt="" width="10px " />
            </CCard>
            <CCollapse :show="modalInfo.collapse" class="border-0 p-0">
              <CCard class="border-0 p-0 mt-2 mb-0 radio_card">
                <CInputRadioGroup
                  :options="modalInfo.radioGroups"
                  class="text-gray"
                  checked="active"
                  @update:checked="updateValues"
                  name="status"
                >
                </CInputRadioGroup>
              </CCard>
            </CCollapse>
          </div>
        </div>
      </CForm>
      <template #footer="{ item }">
        <button type="button" class="btn btn-lightGray" @click="addUser">OK</button>
      </template>
    </CModal>
  </CHeader>
</template>
<script>
import request from "../../plugins/request";
import ModalHOC from "../Modals";
import AddFarmModal from "../Modals/AddFarmModal";
import AddContainerModal from "../Modals/AddContainerModal";
import AddPoolModal from "../Modals/AddPoolModal";
import AddWorkerModel from "../Modals/AddWorkerModel";
import AddTicketModal from "../Modals/AddTicketModal";

const MAP_MODALS = {
  farm: AddFarmModal,
  container: AddContainerModal,
  Pools: AddPoolModal,
  worker: AddWorkerModel,
  ticket: AddTicketModal,
};

const MAP_NAMES = {
  farm: "FILTER_FARMS",
  container: "APPLY_FILTER_FARM",
  miner: "APPLY_FILTER_MINERS",
  Pools: "SEARCH_POOL",
  worker: "SEARCH_WORKER",
};

const MAP_URLS = {
  farm: "Farms",
  container: "Container",
  miner: "Miners",
  Pools: "pool",
  worker: "worker",
};

export default {
  name: "TheHeader",
  props: ["pageUrl", "userInfo"],
  components: {
    ModalHOC,
  },
  data() {
    return {
      warningModal: false,
      loading: false,
      userModal: false,
      searchText: "",
      name_location: "",
      localApi: process.env.VUE_APP_API,
      loadedModalContent: {},
      MAP_MODALS,
      MAP_NAMES,
      MAP_URLS,
      modalInfo: {
        collapse: false,
        radioGroups: [
          { value: "active", label: "Active" },
          { value: "blocked", label: "Blocked" },
          {
            value: "password_blocked",
            label: "Password blocked",
            props: { desription: "description text" },
          },
        ],
        errorList: null,
        curUser: {
          role: "admin",
          status: "active",
        },
        collapseType: false,
      },
    };
  },
  computed: {
    current_component() {
      return this.MAP_MODALS[this.pageUrl] || AddFarmModal;
    },
  },

  methods: {
    updateValues(value, e) {
      this.modalInfo.curUser = {
        ...this.modalInfo.curUser,
        [e.target.name]: value,
      };
    },
    goBack(e) {
      e.preventDefault();
      this.$router.back();
    },
    triggerModal() {
      this.warningModal = !this.warningModal;
      this.$store.commit("miners/triggerEdit", {
        page: this.pageUrl,
        status: "add",
      });
    },
    checkValues() {
      this.modalInfo.curUser = Object.keys(this.modalInfo.curUser).map((res) => {
        return { [res]: this.modalInfo.curUser[res] };
      });
    },
    search(e) {
      e.preventDefault();
      const queries = { ...this.$route.query };
      delete queries["search"];

      if (this.pageUrl === "user" || this.pageUrl == "ticket") {
        this.$store.commit("miners/setTriggerSearchUser", this.searchText);
        return;
      }

      this.$store
        .dispatch("miners/" + MAP_NAMES[this.pageUrl], {
          body: {
            ...queries,
            search: this.searchText,
          },
          page: this.$route.params.id,
        })
        .then((res) => {
          if (this.pageUrl === "Pools")
            this.$router.push(
              `/${this.MAP_URLS[this.pageUrl]}/1?poolName=${this.searchText}`
            );
          else if (this.pageUrl === "worker")
            this.$router.push(
              `/${this.MAP_URLS[this.pageUrl]}/1?workerName=${this.searchText}`
            );
          else if (this.pageUrl !== "container")
            this.$router.push(`/${this.MAP_URLS[this.pageUrl]}/${res.url}`);
          else this.$router.push(`/${this.MAP_URLS[this.pageUrl]}/${res}`);
        });
    },
    addUser() {
      this.loading = true;
      this.modalInfo.curUser.full_name =
        this.modalInfo.curUser.first_name + " " + this.modalInfo.curUser.last_name;
      this.modalInfo.curUser.password = Math.random().toString(36).slice(2);
      request
        .post("/api/v1/users", this.modalInfo.curUser)
        .then(() => {
          this.warningModal = false;
          this.loading = false;
          this.userModal = false;
          this.modalInfo.errorList = null;
          this.$emit("triggerChange", "");
        })
        .catch((err) => {
          if (err.response.data.errors) {
            this.modalInfo.errorList = err.response.data.errors;
          }
          this.loading = false;
        });
    },
  },
};
</script>
